/* Loading spinner animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 0.5;
}

.loading {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007aff;
  animation: spin 1s linear infinite;
}