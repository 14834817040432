.bg-container {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
}
.bg-container img {
  position: absolute;
  /*z-index: 1;*/
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
