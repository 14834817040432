.form-auth-wrapper {
    max-width: 440px;
    margin: 0 auto;
    min-height: 50vh;

    .title {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 20px;

        @media (max-width: 991px) {
            font-size: 24px;
        }
    }

    label {
        font-size: 18px;
        font-weight: 600;

        @media (max-width: 991px) {
            font-size: 16px;
        }
    }

    input {
        border: 1px solid white;
        border-radius: 4px;
        background-color: transparent;
        color: white;
        padding: 10px 20px;

        &:focus {
            background-color: transparent;
            border: 1px solid white;
            caret-color: white;
        }
    }

    .form-control {
        color: white;
    }

    .form-auth-checkbox {
        input[type="checkbox"] {
            border-radius: 0;
            padding: 4px;

            &:checked {
                background-color: transparent;
                border-color: orange;
            }
        }

        label {
            font-size: 14px;
            font-weight: 400;
            color: #9e9e9e;
        }
    }

    a {
        color: #019dd1e6;
        transition: .2s ease-in-out;

        &:hover {
            color: orange;
        }
    }

    .form-auth-btn {
        width: 100%;
        color: white;
        padding: 10px 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        background-color: #019dd1;
        transition: .2s ease-in-out;

        @media (max-width: 991px) {
            font-size: 16px;
        }

        &:hover {
            background-color: orange;
        }
    }
}